import { useRoute, computed } from '#imports'

export default function useEditMode() {
  const route = useRoute()
  const editMode = computed(() => route.fullPath.includes('/administration/ma-page-publique'))
  const isExplore = computed(() => route.fullPath.includes('/e/'))

  return {
    editMode,
    isExplore,
  }
}
